<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="栏目内容"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOk">
          确定
        </a-button>
      </template>
      <div v-html="content" />
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'ShowContent',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleOk(e) {
      this.isShow = false
    }
  }
}
</script>
